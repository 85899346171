/* globals jQuery, Modernizr */

/**
 *
 */
function fnEqualiseHeights(oElements) {
	var iCurrentOffset, iElementsCount, i, iRowStart, iRowEnd, iTargetHeight;

	iCurrentOffset = 0;
	iElementsCount = oElements.length;

	oElements.css('min-height', 0);

	i = 0;
	while(i < iElementsCount) {
		iCurrentOffset = oElements.eq(i).offset().top;
		iRowStart = iRowEnd = i;
		iTargetHeight = 0;

		while(i < iElementsCount && oElements.eq(i).offset().top === iCurrentOffset) {
			iTargetHeight = Math.max(iTargetHeight, oElements.eq(i).outerHeight());
			iRowEnd++;
			i++;
		}

		oElements.slice(iRowStart, iRowEnd).css('min-height', iTargetHeight + 'px');
	}
}

(function($) {

	'use strict';

	var iPageBannersTimeoutDelta = 5000,
		iPageBannersTimeoutHandle = false;


	/**
	 *
	 */
	function fnEqualiseNewsListHeights() {
		fnEqualiseHeights($('.news-list .news-list-item > .inner'));
	}

	/**
	 *
	 */
	function fnEqualiseCategoryLinkHeights() {
		var oListItems;

		oListItems = $('.category-links .links .link');

		fnEqualiseHeights($('.title', oListItems));
		fnEqualiseHeights($('.preview', oListItems));
	}

	/**
	 *
	 */
	function fnEqualiseSearchResultHeights() {
		var oItems;

		oItems = $('section.search-results .results-list.entries .result > .inner');

		fnEqualiseHeights($('.title', oItems));
		fnEqualiseHeights(oItems);
	}

	/**
	 *
	 */
	function fnEqualisePageContactsHeights() {
		fnEqualiseHeights($('section.page-contacts .contact .title'));
	}










	/**
	 *
	 * /
	function fnToggleDesktopSubNav(oEvent) {
		var oClickedLink, iDataId, oSubNavsContainer, oSubNavToOpen,
			bAlreadyOpen, oPrimaryLinks;

		oEvent.preventDefault();

		// Get the ID of the subnav to open
		oClickedLink = $(oEvent.currentTarget);
		iDataId = oClickedLink.attr('data-id');

		// Get the sub navs container and the sub nav we're dealing with
		oSubNavsContainer = $('#desktop-nav');
		oSubNavToOpen = $('.sub-nav[data-id="' + iDataId + '"]', oSubNavsContainer);

		//
		oPrimaryLinks = oClickedLink.parents('.primary-links');

		// Check if the sub nav is already open
		bAlreadyOpen = oSubNavToOpen.hasClass('open');

		// Close all sub navs and deactivate all primary links
		$('.sub-nav', oSubNavsContainer).removeClass('open');
		$('a[data-id]', oPrimaryLinks).removeClass('active');

		// Open the sub nav if it was not already open
		if(!bAlreadyOpen) {
			oClickedLink.addClass('active');
			oSubNavToOpen.addClass('open');

			// Hack to focus the search field
			$('form input[name="k"]', oSubNavToOpen).focus();
		}
	}*/

	/****/
	function fnShowDesktopSubNav(oEvent) {
		var oPrimaryLink, oPrimaryListItem;

		oEvent.preventDefault();

		oPrimaryListItem = $(oEvent.currentTarget);
		oPrimaryLink = $('> a', oPrimaryListItem).eq(0);

		oPrimaryLink.addClass('active');
		oPrimaryListItem.addClass('active');

		// Hack to focus the search field
		$('form input[name="k"]', oPrimaryListItem).focus();
	}

	/****/
	function fnHideDesktopSubNav(oEvent) {
		var oPrimaryLink, oPrimaryListItem;

		oEvent.preventDefault();

		oPrimaryListItem = $(oEvent.currentTarget);
		oPrimaryLink = $('> a', oPrimaryListItem).eq(0);

		oPrimaryLink.removeClass('active');
		oPrimaryListItem.removeClass('active');
	}

	/**
	 *
	 */
	function fnHeaderDesktopNestedClick(oEvent) {
		var oClickedLink, oCurrentTier, iLinkTier, iOffset, oTier2List, oInner;

		oEvent.preventDefault();

		oClickedLink = $(oEvent.currentTarget);
		oCurrentTier = oClickedLink.parents('ul').eq(0);
		oInner = oClickedLink.parents('.inner').eq(0);
		oTier2List = oClickedLink.parents('.tier-2');

		// -1 because I had to move the primary nav after all this was written, that's why
		iLinkTier = oClickedLink.parents('ul').length - 1;

		// Hide ALL sub tiers
		oCurrentTier.find('ul').hide();

		// Show the sub-tier next to the clicked link
		oClickedLink.next('ul').show();

		// Make sure all items can fit in the list item
		oInner.css('min-height', oClickedLink.next('ul').height() + 'px');

		// Move the whole nav item to show the next tier
		iOffset = (iLinkTier - 1) * 100;
		oTier2List.css('left', '-' + iOffset + '%');
	}

	/**
	 *
	 */
	function fnHeaderDesktopBackClick(oEvent) {
		var oClickedLink, iLinkTier, iOffset, oTier2List, oInner;

		oEvent.preventDefault();

		oClickedLink = $(oEvent.currentTarget);
		oInner = oClickedLink.parents('.inner').eq(0);
		oTier2List = oClickedLink.parents('.tier-2');

		// -1 because I had to move the primary nav after all this was written, that's why
		iLinkTier = oClickedLink.parents('ul').length - 1;

		// Make sure all items can fit in the list item
		oInner.css('min-height', oClickedLink.parents('ul').eq(1).height() + 'px');

		// Move the whole nav item to show the previous tier
		iOffset = (iLinkTier - 3) * 100;
		oTier2List.css('left', '-' + iOffset + '%');
	}

	/**
	 *
	 */
	function fnCloseDesktopSubNav(oEvent) {
		oEvent.preventDefault();

		$('#desktop-header .primary-links a').removeClass('active');
		$('#desktop-nav .sub-nav').removeClass('open');
	}

	/**
	 *
	 */
	function fnHeaderMobileNestedClick(oEvent) {
		var oClickedLink, oCurrentTier, iLinkTier, oTopTier;

		oEvent.preventDefault();

		oClickedLink = $(oEvent.currentTarget);
		oCurrentTier = oClickedLink.parents('ul').eq(0);
		oTopTier = $('#mobile-nav .tier-1');
		iLinkTier = oClickedLink.parents('ul').length;

		// Hide ALL sub tiers
		oCurrentTier.find('ul').hide();

		// Show the sub-tier next to the clicked link
		oClickedLink.next('ul').show();

		oCurrentTier.scrollTop(0);
		oCurrentTier.animate({
			'scrollLeft': oCurrentTier.width() + 'px'
		});
	}

	/**
	 *
	 */
	function fnHeaderMobileBackClick(oEvent) {
		var oClickedLink, oParentTier;

		oEvent.preventDefault();

		oClickedLink = $(oEvent.currentTarget);
		oParentTier = oClickedLink.parents('ul').eq(1);

		oParentTier.scrollTop(0);
		oParentTier.animate({
			'scrollLeft': 0
		});
	}

	/**
	 *
	 */
	function fnToggleMobileNav(oEvent) {
		oEvent.preventDefault();
		$('#mobile-header .menu-toggle').toggleClass('active');
		$('#mobile-nav').toggleClass('open');
	}

	/**
	 *
	 */
	function fnToggleMobileSearch(oEvent) {
		oEvent.preventDefault();
		$('#mobile-header .search-toggle').toggleClass('active');
		$('#mobile-search').toggleClass('open');
	}






	/**
	 *
	 */
	function fnSetupProductHeaderGallery() {
		// Abort if the plugin hasn't loaded
		if(!$.fn.slick) {
			return;
		}

		$('section.product-header .gallery .thumbnails').slick({
			'arrows': true,
			'dots': false,
			'infinite': true,
			'slidesToShow': 3,
			'slidesToScroll': 1,
			'vertical': true,
			'verticalSwiping': true,
			'responsive': [{
				'breakpoint': 768,
				'settings': {
					'vertical': true,
					'verticalSwiping': true
				}
			}, {
				'breakpoint': 640,
				'settings': {
					'slidesToShow': 3,
					'vertical': false,
					'verticalSwiping': false
				}
			}, {
				'breakpoint': 480,
				'settings': {
					'slidesToShow': 2,
					'vertical': false,
					'verticalSwiping': false
				}
			}]
		});
	}

	/**
	 *
	 */
	function fnSetupProductHeaderImagePopup() {
		// Don't continue without the plugin
		if(!$.fn.magnificPopup) {
			return;
		}

		$('section.product-header .gallery .preview').magnificPopup({
			'type': 'image'
		});
	}

	/**
	 *
	 */
	function fnShowProductHeaderImagePreview(oEvent) {
		var oLink, sPreviewUrl, sFullUrl, oGalleryContainer, oPreview;
		oEvent.preventDefault();

		oLink = $(oEvent.currentTarget);
		oGalleryContainer = oLink.parents('.gallery').eq(0);
		oPreview = oGalleryContainer.find('.preview');

		sFullUrl = oLink.attr('data-url');
		sPreviewUrl = oLink.attr('data-preview-url');

		oPreview.attr('href', sFullUrl).css('background-image', 'url(' + sPreviewUrl + ')');
	}







	/**
	 *
	 */
	function fnSetupContactFormMapPopup() {
		// Don't continue without the plugin
		if(!$.fn.magnificPopup) {
			return;
		}

		$('section.contact-form .map-link').magnificPopup({
			'type': 'image'
		});
	}





	/**
	 *
	 */
	function fnEqualiseFeaturedCategories() {
		fnEqualiseHeights($('section.featured-categories .category .sub-categories'));
	}



	/**
	 *
	 */
	function fnEqualiseOfficeHeights() {
		fnEqualiseHeights($('section.offices .offices .office > .inner'));
	}

	/**
	 *
	 */
	function fnSetupOfficeMapModals() {
		// Don't continue without the plugin
		if(!$.fn.magnificPopup) {
			return;
		}

		$('section.offices .map-link').magnificPopup({
			'disableOn': 700,
			'type': 'iframe',
			'mainClass': 'mfp-fade',
			'removalDelay': 160,
			'preloader': false,
			'fixedContentPos': false
		});
	}






	/**
	 *
	 */
	function fnChangeProductDetailTabs(oEvent) {
		var oLink, oSection, oTab;

		oEvent.preventDefault();

		oLink = $(oEvent.currentTarget);
		oTab = oLink.parents('li').eq(0);
		oSection = oLink.parents('section').eq(0);

		// Ignore clicking on the active tab
		if(oTab.hasClass('active')) {
			return;
		}

		$('.tabs .active', oSection).removeClass('active');
		$('.panels .active', oSection).removeClass('active');

		oTab.addClass('active');
		$('.panels .panel', oSection).eq(oTab.index()).addClass('active');
	}










	/**
	 *
	 */
	function fnSetupContactForm() {
		var oSection, sProductName, oContactForm;

		oSection = $('section.contact-form');
		if(oSection.length === 0) {
			return;
		}

		oContactForm = $('#contact-form', oSection);

		sProductName = oSection.data('product-name');
		$('.product-field input', oContactForm).val(sProductName);
	}

	/**
	 *
	 */
	function fnSetupPageBanners() {
		var oSection, oBanners;

		oSection = $('section.page-banners');
		oBanners = $('.banners', oSection);

		// Attach events
		oSection.on('click', '.dots .dot', fnPageBannersDotClicked);
		oSection.on('mouseenter', '.banners', fnPageBannersClearTimeout);
		oSection.on('mouseleave', '.banners', fnPageBannersSetTimeout);

		// Click the active dot for setup
		$('.dots .dot.active', oSection).click();

		//
		fnPageBannersSetTimeout();
	}

	/**
	 *
	 */
	function fnPageBannersSetTimeout() {
		iPageBannersTimeoutHandle = setTimeout(fnPageBannersAutoScroll, iPageBannersTimeoutDelta);
	}

	/**
	 *
	 */
	function fnPageBannersClearTimeout() {
		if(!isNaN(iPageBannersTimeoutHandle)) {
			clearTimeout(iPageBannersTimeoutHandle);
		}
		iPageBannersTimeoutHandle = false;
	}

	/**
	 *
	 */
	function fnPageBannersAutoScroll(oEvent) {
		var oSection, oBanners, oNextDot;

		fnPageBannersClearTimeout();

		oSection = $('section.page-banners');
		oBanners = $('.banners', oSection);

		// Get the next dot (or use the first)
		oNextDot = $('.dots .dot.active', oBanners).next();
		if(oNextDot.length === 0) {
			oNextDot = $('.dots .dot', oBanners).eq(0);
		}

		// Click the next dot to move the banner along
		oNextDot.click();

		fnPageBannersSetTimeout();
	}

	/**
	 *
	 */
	function fnPageBannersDotClicked(oEvent) {
		var oDot, oBanners, iBannerIndex, sActiveStyle;

		oEvent.preventDefault();

		oDot = $(oEvent.currentTarget);
		oBanners = oDot.parents('.banners').eq(0);

		//
		iBannerIndex = parseInt(oDot.data('index'), 10);

		// Deactive current
		$('.banner.active, .dot.active', oBanners).removeClass('active');

		// Activate new
		$('.banner', oBanners).eq(iBannerIndex).addClass('active');
		$('.dot', oBanners).eq(iBannerIndex).addClass('active');

		//
		sActiveStyle = 'style-' + $('.banner.active', oBanners).data('style');
		oBanners.removeClass('style-light style-dark');
		oBanners.addClass(sActiveStyle);
	}















	/**
	 *
	 */
	function fnSetupProductRangeSlick() {
		// Abort if the plugin hasn't loaded
		if(!$.fn.slick) {
			return;
		}

		$('section.product-range .products-list').slick({
			'arrows': true,
			'dots': false,
			'infinite': true,
			'slidesToShow': 3,
			'slidesToScroll': 1,
			'responsive': [{
				'breakpoint': 1024,
				'settings': {
					'slidesToShow': 2
				}
			}, {
				'breakpoint': 640,
				'settings': {
					'slidesToShow': 1
				}
			}]
		});
	}









	/**
	 *
	 */
	function fnCategoryContentsShowPreview(oEvent) {
		var oLink, sUrl, sPreviewUrl, oPreviewContainer, oCategoryLinks, oLinkListItem;

		if(window.innerWidth > 1024 && oEvent.type === 'click') {
			return;
		}

		oEvent.preventDefault();

		oLink = $(oEvent.currentTarget);
		sUrl = oLink.attr('href');
		sPreviewUrl = oLink.data('preview-url');
		oPreviewContainer = oLink.parents('.category-links-container').eq(0).find('.preview');
		oCategoryLinks = oLink.parents('.category-links').eq(0);
		oLinkListItem = oLink.parents('li').eq(0);

		$('a', oPreviewContainer).attr('href', sUrl);
		$('.preview-image', oPreviewContainer).css('background-image', "url('" + sPreviewUrl + "')");

		oCategoryLinks.find('li.active').removeClass('active');
		oLinkListItem.addClass('active');
	}

	/**
	 *
	 */
	function fnEqualiseCategoryContentsSubCategories() {
		var oCategories;

		oCategories = $('section.category-contents .sub-categories .category');

		fnEqualiseHeights($('.title-container', oCategories));
		fnEqualiseHeights($('.inner', oCategories));
	}










	/**
	 * Toggle the state of the accordion
	 */
	function fnContentAccordionTogglePanel(oEvent) {
		var oHeader, oSection, bAlreadyOpen;

		oEvent.preventDefault();

		oHeader = $(oEvent.currentTarget).parents('.header').eq(0);
		oSection = oHeader.parents('section').eq(0);

		bAlreadyOpen = oHeader.hasClass('active');

		// Close all sections
		$('.header', oSection).removeClass('active');
		$('.content', oSection).removeClass('active');

		// Open the clicked section if it wasn't already open
		if(!bAlreadyOpen) {
			$('.header', oSection).removeClass('active');
			$('.content', oSection).removeClass('active');

			oHeader.addClass('active');
			oHeader.next('.content').addClass('active');
		}
	}

	/**
	 * Close the accordion
	 */
	function fnContentAccordionClose(oEvent) {
		var oSection;

		oEvent.preventDefault();

		oSection = $(oEvent.currentTarget).parents('section').eq(0);

		// Close all sections
		$('.header', oSection).removeClass('active');
		$('.content', oSection).removeClass('active');
	}










	/**
	 * Add respond js for older browsers
	 */
	function fnCheckMediaQuerySupport() {
		var oElement;

		if(!Modernizr.mq('(min-width: 900px)')) {
			oElement = document.createElement('SCRIPT');
			oElement.setAttribute('src', '/resources/third-party/respond/respond.js');
			document.getElementsByTagName('head')[0].appendChild(oElement);
		}
	}

	/**
	 * Add an overlay to the site when the menu is open
	 */
	function fnAddContentOverlay() {
		$('#page-content').addClass('overlaid');
	}

	/**
	 * Remove the site overlay when the menu is closed
	 */
	function fnRemoveContentOverlay() {
		$('#page-content').removeClass('overlaid');
	}

	// add privacy policy link to consent field on contact form
	function fnContactFormConsent() {
		var $contactForm = $('form#contact-form');
		var $consentField = $('#fields-consent');
		if ($contactForm.length && $consentField.length) {
			var $label = $consentField.find('label');
			var text = $label.text();
			var newText = text.replace(/\* See Privacy Notice\./, '<a href="/privacy" title="Read our privacy policy in a new window" target="_blank">* See Privacy Notice</a>');
			$consentField.find('#fields-consent-1').attr('required', true).addClass('required');
			$label.html($label.children());
			$label.append(newText);
		}
	}

	/**
	 *
	 */
	function fnDomReady() {
		var oPageHeader = $('#page-header'),
			oDesktopPrimaryNav = $('#desktop-header #primary-nav', oPageHeader);

		$(window).resize(fnEqualiseNewsListHeights);
		$(window).resize(fnEqualiseCategoryLinkHeights);
		$(window).resize(fnEqualiseSearchResultHeights);
		$(window).resize(fnEqualisePageContactsHeights);

		// Contact section
		$('section.contact-form form').validate();
		fnSetupContactFormMapPopup();

		// Offices section
		$(window).resize(fnEqualiseOfficeHeights);
		fnSetupOfficeMapModals();

		// Header section
		$('#desktop-nav .search-form form').validate(); // Validate desktop search
		$('#mobile-search form').validate(); // Validate mobile search
		//$('#desktop-header #primary-nav', oPageHeader).on('click', '.primary-links a[data-id]', fnToggleDesktopSubNav); // Handle the user clicking on primary nav links
		oDesktopPrimaryNav.on('mouseenter', '.primary-links .has-children', fnShowDesktopSubNav);
		oDesktopPrimaryNav.on('mouseleave', '.primary-links .has-children', fnHideDesktopSubNav);
		$('#primary-nav', oPageHeader).on('click', '.nested-lists .has-children > a', fnHeaderDesktopNestedClick); // Handle clicking on a nested link that has children
		$('#primary-nav', oPageHeader).on('click', '.nested-lists .back > a', fnHeaderDesktopBackClick); // Handle clicking on a nested back button
		$('#primary-nav', oPageHeader).on('click', '.sub-nav .all-container .close', fnCloseDesktopSubNav); // Handle clicking on a nested back button
		$('#mobile-nav', oPageHeader).on('click', '.has-children > a', fnHeaderMobileNestedClick); // Handle clicking on a nested link that has children
		$('#mobile-nav', oPageHeader).on('click', '.back > a', fnHeaderMobileBackClick); // Handle clicking on a nested back button
		$('#mobile-nav', oPageHeader).on('click', '.close', fnToggleMobileNav); // Close the mobile nav
		$('#mobile-header', oPageHeader).on('click', '.menu-toggle', fnToggleMobileNav); // Toggle the mobile nav
		$('#mobile-header', oPageHeader).on('click', '.search-toggle', fnToggleMobileSearch); // Toggle the mobile nav

		oDesktopPrimaryNav.on('mouseenter', '.primary-link.has-children', fnAddContentOverlay);
		oDesktopPrimaryNav.on('mouseleave', '.primary-link.has-children', fnRemoveContentOverlay);

		// Product Header sectopm
		fnSetupProductHeaderGallery();
		$('section.product-header .gallery').on('click', '.thumbnail a', fnShowProductHeaderImagePreview);
		fnSetupProductHeaderImagePopup();

		// Product Detail section
		$('section.product-detail .tabs').on('click', 'a', fnChangeProductDetailTabs);
		$('section.product-detail .tabs a').eq(0).click(); // Activate the first tab

		// Product Range section
		fnSetupProductRangeSlick();

		// Page Banners section
		fnSetupPageBanners();

		// Category Contents
		$('section.category-contents .sub-categories').on('click', '.preview-link', fnCategoryContentsShowPreview);
		$('section.category-contents .sub-categories').on('mouseenter', '.preview-link', fnCategoryContentsShowPreview);
		$(window).resize(fnEqualiseCategoryContentsSubCategories);

		// Content Accordion
		$('section.content-accordion').on('click', '.header a', fnContentAccordionTogglePanel);
		$('section.content-accordion').on('click', '.content .close', fnContentAccordionClose);

		// Add respond js for older browsers
		fnCheckMediaQuerySupport();

		// Features categories
		$(window).resize(fnEqualiseFeaturedCategories);

		// Contact Form section
		fnSetupContactForm();
		fnContactFormConsent();
	}

	/**
	 *
	 */
	function fnWindowLoad() {
		setTimeout(function() {
			$(window).resize();
		}, 200);
	}

	$(document).ready(fnDomReady);
	$(window).load(fnWindowLoad);

}(jQuery));
